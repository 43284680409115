import React, { Component } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import clsx from 'clsx'

import Logo from '../../images/logo-centered.svg'

import './footer.scss'

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = { value: '', disabled: 'disabled' }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleFooterLink = this.handleFooterLink.bind(this)
  }

  handleFooterLink = (e, type) => {
    e.preventDefault()
    this.props.toggleModal(type)
  }
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.submitZip(this.state.value)
    this.props.nextStep('zip')
    navigate('/apply/birth')
  }
  handleChange(event) {
    const isDisabled =
      event.target.value !== '' && event.target.value.length === 5 ? '' : 'disabled'
    this.setState({ value: event.target.value, disabled: isDisabled })
  }

  render = () => {
    const { phone, captive } = this.props
    let { hideCta, footerAlt } = this.props
    const { disabled, value } = this.state

    if (captive.newApply) {
      hideCta = true
      footerAlt = true
    }

    const footerClass = footerAlt ? 'alt' : ''

    return (
      <footer id="footer" className={clsx(footerClass)}>
        <div className="container">
          {!hideCta && !footerAlt && (
            <div className="cta-holder">
              <span className="text">
                Speak with a licensed <br />
                insurance agent! <br />
              </span>
              <a
                href={`tel:${phone}`}
                className={clsx('btn with-icon', this.props.TTYBig && 'TTYBig')}
              >
                <span className="icon-phone" /> <span className="text">{phone}</span>
              </a>
            </div>
          )}
          {footerAlt && !hideCta && (
            <div className={clsx(!this.props.hideOnline && 'cta-new', 'mb5')}>
              {!this.props.hideOnline && (
                <div className="w-50 w-100-s t-center mb4-m">
                  <h3 className="t-bold pb2">Compare medicare plans online</h3>
                  <div className="inline">
                    <form action="#" onSubmit={this.handleSubmit} noValidate="novalidate">
                      <input
                        className="mr4 mr3-m"
                        id="zip"
                        placeholder="Zip Code"
                        maxLength="5"
                        type="text"
                        data-required="true"
                        value={value}
                        onChange={this.handleChange}
                      />
                      <button
                        type="submit"
                        disabled={disabled}
                        className={`btn` + (disabled ? ` disabled` : ``)}
                      >
                        Start my quote
                      </button>
                    </form>
                  </div>
                </div>
              )}
              <div
                className={clsx(!this.props.hideOnline && 'w-50', 'w-100-s', 't-center')}
              >
                <h3 className="t-bold pb2">Speak to a licensed agent</h3>
                <a href={`tel:${phone}`} className="btn with-icon btn-large">
                  <span className="icon-phone" /> <span className="text">{phone}</span>
                </a>
              </div>
            </div>
          )}
          {footerAlt && (
            <div className="logo t-center mb4">
              <img src={Logo} alt="Medicare Companion" />
            </div>
          )}
          <div className="menu-holder">
            <ul className="footer-menu">
              {/* <li>
                <span className="copyright">
                  &copy; 2021 <Link to="/">MedicareCompanion</Link>
                </span>
              </li> */}
              <li>
                <Link onClick={(e) => this.handleFooterLink(e, 'terms')} to="/">
                  Terms of Services
                </Link>
              </li>
              <li>
                <Link onClick={(e) => this.handleFooterLink(e, 'privacy')} to="/">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <a href="/ccpa" target="_blank">
                  CCPA
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-cege">
          <p>A privately owned, non-government website powered by PolicyScout.</p>
          {this.props.addLegalCopy && (
            <p style={{ margin: '2rem' }}>
              This is a solicitation of insurance. Callers will be connected with a
              third-party partner of Quotes.MedicareCompanion.org who can provide more
              information about medicare plans, life insurance and other products and
              services from one or several insurance carriers.
            </p>
          )}
          {this.props.addLegalCopy && (
            <p style={{ margin: '0 2rem' }}>
              This is a solicitation of insurance. Outside of the Medicare Annual
              Enrollment Period, members can enroll in a plan only if they meet certain
              criteria. A licensed insurance agent can help you determine whether you are
              eligible. Plan availability varies by region and state. Callers will be
              directed to a licensed insurance agent with a third-party partner of
              MedicareCompanion.org and/or PolicyScout who can provide more information
              about Medicare Supplement Insurance and/or Medicare Advantage plans offered
              by one or several insurance carrier(s). Enrollment in any plan depends on
              contract renewal. Medicare has neither reviewed nor endorsed the information
              contained in this advertisement. Not connected with, or endorsed by, the
              U.S. government or the federal Medicare program. This information is not a
              complete description of benefits. For a complete listing of Medicare plans,
              please contact 1-800-MEDICARE (TTY users should call 1-877-486-2048), 24
              hours a day, 7 days a week, or consult www.medicare.gov.
            </p>
          )}
        </div>
      </footer>
    )
  }
}

Footer.propTypes = {
  phone: PropTypes.string,
  hideCta: PropTypes.bool,
  footerAlt: PropTypes.bool,
  hideOnline: PropTypes.bool,
  TTYBig: PropTypes.bool,
  addLegalCopy: PropTypes.bool,
}

Footer.defaultProps = {
  phone: ``,
  hideCta: false,
  footerAlt: false,
  hideOnline: false,
  TTYBig: false,
  addLegalCopy: false,
}

export default Footer
