import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'
import uniq from 'lodash/uniq'

import './partners.scss'

const Partners = () => {
  const data = useStaticQuery(graphql`
    query GetTCPAMarketingPartners {
      allContentfulTcpaMarketingPartners {
        nodes {
          companionMarketingPartnersList {
            all
          }
        }
      }
    }
  `)

  const marketingPartnersList = get(
    data,
    'allContentfulTcpaMarketingPartners.nodes[0].companionMarketingPartnersList.all',
    [],
  )

  const orderedMarketingPartnersList = uniq(marketingPartnersList).sort((a, b) =>
    a.toLowerCase().localeCompare(b.toLowerCase()),
  )

  return (
    <div className="partners">
      <div className="section-heading">
        <h2>List of Insurance Companies &amp; Agencies That May Be Contacting You</h2>
      </div>
      <div className="text-holder with-decor">
        <span>
          <a
            href="https://assuremymedicare.com/partners/"
            target="__blank"
            rel="nofollow noreferrer noopener"
          >
            Assure My Medicare's marketing partners
          </a>
        </span>
        <span>
          <a
            href="https://healthmatchup.com/organizations/"
            target="__blank"
            rel="nofollow noreferrer noopener"
          >
            HealthMatchUp's marketing partners
          </a>
        </span>
        <span>
          <a
            href="https://medicarematchup.com/partner-list/"
            target="__blank"
            rel="nofollow noreferrer noopener"
          >
            Medicare's marketing partners
          </a>
        </span>
        <span>
          <a
            href="https://smartfinalexpense.com/partners/"
            target="__blank"
            rel="nofollow noreferrer noopener"
          >
            SmartFinalExpense's marketing partners
          </a>
        </span>
        <span>
          <a
            href="https://autoinsurancematchup.com/organizations/"
            target="__blank"
            rel="nofollow noreferrer noopener"
          >
            AutoInsuranceMatchup's marketing partners
          </a>
        </span>
        <span>
          <a
            href="https://christianplans.com/partners/"
            target="__blank"
            rel="nofollow noreferrer noopener"
          >
            ChristianPlans's marketing partners
          </a>
        </span>
        <span>
          <a
            href="https://leads.digitalmediasolutions.com/partners/medicare"
            target="__blank"
            rel="nofollow noreferrer noopener"
          >
            Digital Media Solutions Medicare Partners
          </a>
        </span>
        <span>
          <a
            href="https://leads.digitalmediasolutions.com/partners/auto-insurance"
            target="__blank"
            rel="nofollow noreferrer noopener"
          >
            Digital Media Solutions Auto Partners
          </a>
        </span>
        <span>
          <a
            href="https://www.medicarepolicychoices.com/marketingpartners/"
            target="__blank"
            rel="nofollow noreferrer noopener"
          >
            MEDICARE POLICY CHOICES Partners
          </a>
        </span>
        <span>
          <a
            href="https://www.quotehound.com/partners"
            target="__blank"
            rel="nofollow noreferrer noopener"
          >
            Quotehound Marketing Partners
          </a>
        </span>
        <span>
          <a
            href="https://www.usfirsthealthcare.com/tcpa"
            target="__blank"
            rel="nofollow noreferrer noopener"
          >
            US First Healthcare Partners
          </a>
        </span>
        <span>
          <a
            href="https://trustedconsumer.com/insurance-28655226511665512/"
            target="__blank"
            rel="nofollow noreferrer noopener"
          >
            Connecting the Dots Marketing Partners
          </a>
        </span>
        <span>
          <a
            href="https://www.usfirsthealthcare.com/tcpa/"
            target="__blank"
            rel="nofollow noreferrer noopener"
          >
            USFirstHealthCare Marketing Partners
          </a>
        </span>
        <span>
          <a
            href="https://resource-connect.com/partners.php"
            target="__blank"
            rel="nofollow noreferrer noopener"
          >
            Resource connect marketing partners
          </a>
        </span>
        <span>
          <a
            href="https://www.usfirsthealthcare.com/tcpa"
            target="__blank"
            rel="nofollow noreferrer noopener"
          >
            US First Healthcare marketing partners
          </a>
        </span>
        {orderedMarketingPartnersList.map((partner) => {
          if (partner?.toLowerCase()?.includes('everquote')) {
            return (
              <>
                <span>
                  <a
                    href="https://plist.everquote.com/partners.html"
                    target="__blank"
                    rel="nofollow noreferrer noopener"
                  >
                    EverQuote's marketing partners
                  </a>
                </span>
                <span>Everquote Inc</span>
              </>
            )
          }
          return <span key={partner}>{partner}</span>
        })}
      </div>
      <br />
      <a href="/partners" target="__blank">
        Click Here To View All
      </a>
    </div>
  )
}

export default Partners
